/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  function isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var productName = $('#mepr-account-subscriptions-table .mepr-account-product').text();
        var productURL = '/register/';
        productName = productName.toLowerCase();

        if ($('#mepr-account-subscriptions-table')) {
          if (productName.indexOf('professional') !== -1) {
            productURL = productURL + 'member';
          } else if (productName.indexOf('retired') !== -1) {
            productURL = productURL + 'retired-member';
          } else if (productName.indexOf('student') !== -1) {
            productURL = productURL + 'student';
          } else if (productName.indexOf('junior') !== -1) {
            productURL = productURL + 'junior-technical';
          } else {
            productURL = productURL + 'member';
          }
          // console.log(productName);
          // console.log(productURL);
          // console.log('<a href="'+productURL+'"></a>');
          $('<a href="' + productURL + '" class="button-primary" style="margin-top: 2em; text-transform: uppercase;">Renew</a>').insertAfter('#mepr-account-subscriptions-table');
        }

        // $('.mepr-signup-form .mepr-submit').click(function(){
        //   if($('#payment-form')){
        //     $('#payment-form .mepr-submit').val('PAY NOW');
        //   }
        // });

        $('#mepr-account-subscriptions').html('Renewals');

        if ($(document).width() < 800) {
          $(".page .main").fitVids();
        }

        $('.see-more').click(function() {
          if ($(this).find('button').hasClass('pull-up')) {
            $(this).find('button').removeClass('pull-up');
          } else {
            $(this).find('button').addClass('pull-up');
          }
          $(this).parent().parent().find('.detailed-content').slideToggle(200);
        });

        $('.hamburger').click(function() {
          if ($(this).hasClass('is-active')) {
            $(this).removeClass('is-active');
            $('.nav-search').removeClass('open-menu');
            $('.mobile-bg').removeClass('open-menu-bg');
            // $('.nav-primary .sub-menu').css({'display': 'none'});
          } else {
            $(this).addClass('is-active');
            $('.nav-search').addClass('open-menu');
            $('.mobile-bg').addClass('open-menu-bg');
          }
        });

        $('.mobile-bg').click(function() {
          $('.hamburger').removeClass('is-active');
          $('.nav-search').removeClass('open-menu');
          $('.mobile-bg').removeClass('open-menu-bg');
          $('.nav-primary .sub-menu').css({ 'display': 'none' });
        });

        // if($(document).width() < 1024){
        //   $('.nav-primary ul .menu-item-has-children > a').on('touchend',function(event){
        //     event.stopPropagation();
        //     //console.log('Click!');
        //     // if($(this).parent().hasClass('mobile-open')){
        //     //   $(this).parent().removeClass('mobile-open');
        //     // }else{
        //     //   $(this).parent().addClass('mobile-open');
        //     // }
        //     //$('.nav-primary .sub-menu').hide();
        //     $('.nav-primary .sub-menu').css({'display': 'none'});
        //     $(this).next().slideDown();

        //     return false;

        //   });
        // }
      }
    },
    'registration': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // $('#gform_ajax_frame_2').on('load', function() {
        //   $('.gform_wrapper .monday-workshop-container').wrapAll("<div class='workshop-wrapper'></div>");
        //   $('.gform_wrapper .tuesday-workshop-container').wrapAll("<div class='workshop-wrapper'></div>");
        //   $('.membership .gfield_radio li input').each(function(index, el) {
        //     var membershipValue = $(this).val();
        //     var membershipFee = membershipValue.replace(/\D/g, '');
        //     $(this).parent().append('<span class="fee-ammount">$' + parseFloat(membershipFee).toFixed(2) + ' AUD</span>');
        //   });
        //   $('.membership .gfield_radio li input').click(function() {
        //     $('.membership .gfield_radio li input:not(:checked)').parent().removeClass("selected");
        //     $('.membership .gfield_radio li input:checked').parent().addClass("selected");
        //   });
        //   var microbeamUXRF = $("input[value*='(μXRF)']");
        //   var mechanicalPrep = $('input[value*="Mechanical"]');
        //   var largeMapping = $('input[value*="Mapping"]');
        //   var advancedPrep = $('input[value*="Advanced"]');
        //   var laserBreakdown = $('input[value*="(LIBS)"]');
        //   var softXray = $('input[value*="Light"]');
        //   var hrSem = $('input[value*="(HR‑SEM)"]');
        //   var eels = $('input[value*="(EELS)"]');
        //   var cl = $('input[value*="(CL)"]');
        //   var ebsd = $('input[value*="(EBSD)"]');
        //   var epma = $('input[value*="(EPMA)"]');
        //   // Get the workshoplinks from the entered values in the ACF Fields
        //   $('.session-group .gfield_radio li input').each(function(index, el) {
        //     var workshopValue = $(this).val();
        //     var workshopPrice = workshopValue.replace(/\D/g, '');
        //     $(this).parent().append('<span class="workshop-ammount">$' + parseFloat(workshopPrice).toFixed(2) + ' AUD</span>');
        //   });
        //   $('.workshop-wrapper .gfield_radio li input').click(function() {
        //     $('.workshop-wrapper .gfield_radio li input:not(:checked)').parent().removeClass("selected");
        //     $('.workshop-wrapper .gfield_radio li input:checked').parent().addClass("selected");
        //   });
        //   $('.workshop-wrapper .gfield_radio li input[value="None|0"]').each(function(index, el) {
        //     $(this).parent().children('.workshop-ammount').hide();
        //   });
        //   microbeamUXRF.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#uxrf" class="information" target="_blank">See info</a>');
        //   mechanicalPrep.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#specprep_am" class="information" target="_blank">See info</a>');
        //   largeMapping.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#xray_mapping" class="information" target="_blank">See info</a>');
        //   advancedPrep.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#specprep_pm" class="information" target="_blank">See info</a>');
        //   laserBreakdown.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#libs" class="information" target="_blank">See info</a>');
        //   softXray.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#sxes" class="information" target="_blank">See info</a>');
        //   hrSem.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#sem" class="information" target="_blank">See info</a>');
        //   eels.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#eels" class="information" target="_blank">See info</a>');
        //   cl.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#cl" class="information" target="_blank">See info</a>');
        //   ebsd.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#ebsd" class="information" target="_blank">See info</a>');
        //   epma.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#epma" class="information" target="_blank">See info</a>');
        //   $('.social .gfield_checkbox li input, .social-plus .gfield_checkbox li input').click(function() {
        //     $('.social .gfield_checkbox li input:not(:checked), .social-plus .gfield_checkbox li input:not(:checked)').parent().removeClass("selected");
        //     $('.social .gfield_checkbox li input:checked, .social-plus .gfield_checkbox li input:checked ').parent().addClass("selected");
        //   });
        // });
        // $('.gform_wrapper .monday-workshop-container').wrapAll("<div class='workshop-wrapper'></div>");
        // $('.gform_wrapper .tuesday-workshop-container').wrapAll("<div class='workshop-wrapper'></div>");
        // $('.membership .gfield_radio li input').each(function(index, el) {
        //   var membershipValue = $(this).val();
        //   var membershipFee = membershipValue.replace(/\D/g, '');
        //   $(this).parent().append('<span class="fee-ammount">$' + parseFloat(membershipFee).toFixed(2) + ' AUD</span>');
        // });
        // $('.membership .gfield_radio li input').click(function() {
        //   $('.membership .gfield_radio li input:not(:checked)').parent().removeClass("selected");
        //   $('.membership .gfield_radio li input:checked').parent().addClass("selected");
        // });
        // var microbeamUXRF = $("input[value*='(μXRF)']");
        // var mechanicalPrep = $('input[value*="Mechanical"]');
        // var largeMapping = $('input[value*="Mapping"]');
        // var advancedPrep = $('input[value*="Advanced"]');
        // var laserBreakdown = $('input[value*="(LIBS)"]');
        // var softXray = $('input[value*="Light"]');
        // var hrSem = $('input[value*="(HR‑SEM)"]');
        // var eels = $('input[value*="(EELS)"]');
        // var cl = $('input[value*="(CL)"]');
        // var ebsd = $('input[value*="(EBSD)"]');
        // var epma = $('input[value*="(EPMA)"]');
        // $('.session-group .gfield_radio li input').each(function(index, el) {
        //   var workshopValue = $(this).val();
        //   var workshopPrice = workshopValue.replace(/\D/g, '');
        //    $(this).parent().append('<span class="workshop-ammount">$' + parseFloat(workshopPrice).toFixed(2) + ' AUD</span>');
        // });
        // $('.workshop-wrapper .gfield_radio li input').click(function() {
        //   $('.workshop-wrapper .gfield_radio li input:not(:checked)').parent().removeClass("selected");
        //   $('.workshop-wrapper .gfield_radio li input:checked').parent().addClass("selected");
        // });
        // $('.workshop-wrapper .gfield_radio li input[value="None|0"]').each(function(index, el) {
        //   $(this).parent().children('.workshop-ammount').hide();
        // });
        // microbeamUXRF.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#uxrf" class="information" target="_blank">See info</a>');
        // mechanicalPrep.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#specprep_am" class="information" target="_blank">See info</a>');
        // largeMapping.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#xray_mapping" class="information" target="_blank">See info</a>');
        // advancedPrep.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#specprep_pm" class="information" target="_blank">See info</a>');
        // laserBreakdown.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#libs" class="information" target="_blank">See info</a>');
        // softXray.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#sxes" class="information" target="_blank">See info</a>');
        // hrSem.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#sem" class="information" target="_blank">See info</a>');
        // eels.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#eels" class="information" target="_blank">See info</a>');
        // cl.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#cl" class="information" target="_blank">See info</a>');
        // ebsd.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#ebsd" class="information" target="_blank">See info</a>');
        // epma.parent().append('<a href="http://www.microscopy.com/AMAS2019/workshops.html#epma" class="information" target="_blank">See info</a>');
        // $('.social .gfield_checkbox li input, .social-plus .gfield_checkbox li input').click(function() {
        //   $('.social .gfield_checkbox li input:not(:checked), .social-plus .gfield_checkbox li input:not(:checked)').parent().removeClass("selected");
        //   $('.social .gfield_checkbox li input:checked, .social-plus .gfield_checkbox li input:checked ').parent().addClass("selected");
        // });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $('.slider-container').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },

    'society_office_bearers': {
      init: function() {
        // JavaScript to be fired on the about us page
      },

      finalize: function() {

        $('.contacts-list-see-more').click(function() {

          if ($(this).find('button').hasClass('pull-up')) {
            $(this).find('button').removeClass('pull-up');
          } else {
            $(this).find('button').addClass('pull-up');
          }
          $(this).parent().parent().find('.contacts-list-data').slideToggle();

        });
      }
    },

    'single_memberpressproduct': {
      init: function() {

      },

      finalize: function() {

        var priceString = $('.mepr_price_cell').text();
        priceString = priceString.replace('access', 'membership');
        //console.log(priceString);
        $('.mepr_price_cell').html(priceString);
        $('.mepr_coupon .mp-form-label label').html('Coupon Code (if applicable):');
        $('.mp-password-strength-area span small em').html('Minimum eight-character password with numbers/symbols and mixed-case letters');
        $('.mepr_math_captcha label').append('<span> (Checking you are not a spam robot.)</span>');

        var amasCheckBox = document.querySelector('input[name="mepr_amas_or_lms[amas]"] ~ .mepr-form-checkboxes-label');
        var lmaCheckBox = document.querySelector('input[name="mepr_amas_or_lms[lma]"] ~ .mepr-form-checkboxes-label');

        var newElement = document.createElement('a');
        newElement.setAttribute("href", "/amas");
        newElement.innerHTML = amasCheckBox.innerHTML;

        amasCheckBox.innerHTML = "";
        amasCheckBox.appendChild(newElement);

        newElement = document.createElement('a');
        newElement.setAttribute("href", "/lma");
        newElement.innerHTML = lmaCheckBox.innerHTML;

        lmaCheckBox.innerHTML = "";
        lmaCheckBox.appendChild(newElement);


        //$('#mepr_amas_or_lms')
      }
    },

    'blog': {
      finalize: function() {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        var offset = 5;

        function resetNewsClone() {
          $('.cloning .image img').attr('src', '');
          $('.cloning .details').html('');
          $('.cloning .image a').attr('href', '');
          $('.cloning .news-category').html('');
          $('.cloning .news-title a').attr('href', '');
          $('.cloning .news-title a').html('');
          $('.cloning .news-date').html('');
          $('.cloning .news-excerpt').html('');

        }

        function fillNewsClone(data) {
          var slug = "",
            slugName = "";
          console.log(data);
          if (data.pure_taxonomies.categories) {
            slug = data.pure_taxonomies.categories[0].slug;
            slugName = data.pure_taxonomies.categories[0].name;
          }

          if (data.better_featured_image) {
            $('.cloning .image img').attr('src', data.better_featured_image.source_url);
          } else {
            $('.cloning .image img').attr('src', "/wp-content/themes/amms-wordpress-theme/dist/images/news-default.jpg");
          }

          $('.cloning .details').html(slugName);

          $('.cloning .image a').attr('href', data.guid.rendered);
          $('.cloning .news-category').html(slugName);
          $('.cloning .news-title a').attr('href', data.guid.rendered);
          $('.cloning .news-title a').html(data.title.rendered);

          $('.cloning .news-date').html(moment(data.date).format('Do MMMM YYYY'));

          $('.cloning .news-excerpt').html(data.excerpt.rendered);
        }


        $('.button-load').click(function(event) {

          event.stopPropagation();

          if (!$(this).hasClass('disabled')) {

            $.getJSON("/wp-json/wp/v2/posts?per_page=5&offset=" + offset + "&order=desc", function(data) {
              //console.log(data);
              if (data.length > 0) {

                $.each(data, function(i, sData) {

                  fillNewsClone(sData);

                  $(".cloning .news-item").clone().insertBefore(".blog-page .button-load");

                  resetNewsClone();

                });

                offset = offset + 5;
              } else {
                $('.button-load').addClass('disabled');
                console.log('disabled');
              }
            });
          }

          return false;

        });
      }
    },

    'events': {
      init: function() {},

      finalize: function() {

        if ($(document).width() < 1024) {
          $('.slider-container').css({ 'width': $(document).width() + "px" });
        }

        $(window).on('resize', function() {
          if ($(document).width() < 1024) {
            $('.slider-container').css({ 'width': $(document).width() + "px" });
          }
        });

        $('.slider-container').slick({
          dots: true,
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          autoplaySpeed: 5000,
          fade: true
        });


        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        var offset = 5;

        function resetClone() {
          $('.cloning .event-item').attr('class', 'event-item');
          $('.cloning .image img').attr('src', '');
          $('.cloning .details').html('');
          $('.cloning .image a').attr('href', '');
          $('.cloning .event-category').html('');
          $('.cloning .event-title a').attr('href', '');
          $('.cloning .event-title a').html('');
          $('.cloning .event-date .date').html('');
          $('.cloning .event-venue .place').html('');
        }

        function fillClone(data) {
          var slug = "",
            slugName = "";
          console.log(data);
          if (data.pure_taxonomies.event_types) {
            slug = data.pure_taxonomies.event_types[0].slug;
            slugName = data.pure_taxonomies.event_types[0].name;
          }

          $('.cloning .event-item').addClass('event-' + slug);

          if (data.better_featured_image) {
            $('.cloning .image img').attr('src', data.better_featured_image.source_url);
          } else {
            $('.cloning .image img').attr('src', "/wp-content/themes/amms-wordpress-theme/dist/images/" + slug + "-default.jpg");
          }

          $('.cloning .details').html("<div>" + slugName + " Events</div><span>" + moment(data.acf.start_date).format('DD.MM.YYYY') + "</span>");

          $('.cloning .image a').attr('href', data.guid.rendered);
          $('.cloning .event-category').html(slugName + " Events");
          $('.cloning .event-title a').attr('href', data.guid.rendered);
          $('.cloning .event-title a').html(data.title.rendered);

          $('.cloning .event-date .date').html(moment(data.acf.start_date).format('Do MMMM YYYY'));

          $('.cloning .event-venue .place').html(data.acf.venue);
        }


        $('.button-load').click(function(event) {

          event.stopPropagation();

          if (!$(this).hasClass('disabled')) {

            $.getJSON("/wp-json/wp/v2/events?per_page=5&offset=" + offset + "&order=asc&filter[orderby]=meta_value&meta_key=start_date&meta_type=DATETIME&filter[meta_query][0][key]=start_date&filter[meta_query][0][value]=" + encodeURIComponent(moment({}).format("YYYY-MM-DD")) + "&filter[meta_query][0][compare]=" + encodeURIComponent('>') + "&filter[meta_query][0][type]=datetime", function(data) {
              //console.log(data);
              if (data.length > 0) {

                $.each(data, function(i, sData) {

                  fillClone(sData);

                  $(".cloning .event-item").clone().insertBefore(".events-container .button-load");

                  resetClone();

                });

                offset = offset + 5;
              } else {
                $('.button-load').addClass('disabled');
                console.log('disabled');
              }
            }).fail(function() {
              $('.button-load').addClass('disabled');
            }).done(function() {
              $.getJSON("/wp-json/wp/v2/events?per_page=5&offset=" + offset + "&order=asc&filter[orderby]=meta_value&meta_key=start_date&meta_type=DATETIME&filter[meta_query][0][key]=start_date&filter[meta_query][0][value]=" + encodeURIComponent(moment({}).format("YYYY-MM-DD")) + "&filter[meta_query][0][compare]=" + encodeURIComponent('>') + "&filter[meta_query][0][type]=datetime", function(data) {
                if (isEmpty(data)) {
                  $('.button-load').addClass('disabled');
                }
              });
            });
          }

          return false;

        });

        $.getJSON("/wp-json/wp/v2/events?per_page=5&offset=" + offset + "&order=asc&filter[orderby]=meta_value&meta_key=start_date&meta_type=DATETIME&filter[meta_query][0][key]=start_date&filter[meta_query][0][value]=" + encodeURIComponent(moment({}).format("YYYY-MM-DD")) + "&filter[meta_query][0][compare]=" + encodeURIComponent('>') + "&filter[meta_query][0][type]=datetime", function(data) {
          if (isEmpty(data)) {
            $('.button-load').addClass('disabled');
          }
        });
      }
    },

    'page': {
      init: function() {

      },
      finalize: function() {

        $('.columns-list-see-more').click(function() {

          if ($(this).find('button').hasClass('pull-up')) {
            $(this).find('button').removeClass('pull-up');
          } else {
            $(this).find('button').addClass('pull-up');
          }
          $(this).parent().parent().find('.columns-list-data').slideToggle();

        });

        var maxWidth = false;

        $('.module-icon_list').each(function() {

          $(this).find('.image').each(function(cur) {


            // Get on screen image
            var screenImage = $(this).find('img');

            // Create new offscreen image to test
            var theImage = new Image();
            theImage.src = screenImage.attr("src");

            // Get accurate measurements from that.
            var imageWidth = theImage.width;
            var imageHeight = theImage.height;

            console.log("image", imageWidth);
            console.log("container", $(this).width());

            if (imageWidth >= 100) {
              maxWidth = true;
              return false;
            }

          });

          if (maxWidth) {
            $(this).find('.image').each(function(cur) {
              $(this).addClass('full-image');
            });
          }

          maxWidth = false;

        });

        // $('.icon-list-item .image').each(function(cur){
        //   console.log("image", $(this).find('img')[0]);
        //   console.log("container", $(this).width());

        //   var screenImage = $(this).find('img');

        //   var theImage = new Image();
        //   theImage.src = screenImage.attr("src");

        //   var imageWidth = theImage.width;
        //   if(imageWidth == $(this).width()){
        //     maxWidth = true;
        //     return false;
        //   }

        // });

        // if(maxWidth){
        //   $('.icon-list-item .image').each(function(cur){
        //     $(this).addClass('full-image');
        //   });
        // }

        if (jQuery.featherlightGallery) {

          $.featherlightGallery.prototype.afterContent = function() {
            var caption = this.$currentTarget.parent().find('img').attr('data-caption');
            var title = this.$currentTarget.parent().find('img').attr('data-title');
            var credit = this.$currentTarget.parent().find('img').attr('data-credit');
            this.$instance.find('.caption').remove();
            if (caption) {
              $('<h5 class="caption">').html("<strong>" + title + "</strong>" + "<br/>" + caption + "<br/>" + credit).appendTo(this.$instance.find('.featherlight-content'));
              $('.featherlight-content img').addClass('caption-show');
              $('.caption-show').css({ 'padding-bottom': (($('.caption').height() + 15) * 2) + "px" });
              $('.caption').css({ 'margin-top': "-" + (($('.caption').height() + 15) * 2) + "px" });
            }
          };

          $('.gallery ul li a').featherlightGallery({
            previousIcon: '<i class="fa fa-chevron-left" aria-hidden="true"></i>',
            nextIcon: '<i class="fa fa-chevron-right" aria-hidden="true"></i>',
            galleryFadeIn: 300,
            openSpeed: 300
          });

        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
